// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import jQuery from "jquery"
// import 'jquery-ui-dist/jquery-ui.js'
// import 'jquery-ui-dist/jquery-ui.css'

// window.jQuery = jQuery
// window.$ = jQuery

// require("rails-ujs").start()
// require("turbolinks").start()
// require("channels")
// import "bootstrap/dist/js/bootstrap"



// import 'bootstrap'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap/dist/js/bootstrap"

import 'jquery-colorbox'
// import 'modernizr'
import 'owl.carousel'
import 'magnific-popup'

import 'packs/animatescroll.min'
// import 'packs/jquery.themepunch.revolution.min'
// import 'packs/template'


import "../src/stylesheets/stylesheet.scss"
const images = require.context('../images', true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()
